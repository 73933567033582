import { ServiceSpecificationApiToolCategoryEnum } from "@app/@generated";

export const PROJECT_TITLE = "Apigale. API Business Platform";

export enum API_EDITOR_MODE {
  EDITOR = "editor",
  PREVIEW = "preview",
  ALL = "all",
}

export enum UserRoleEnum {
  GUEST = "guest",
  FULL = "full",
}

export const MAX_PATH_LENGTH = 13;

export const IMAGE_TRASH_URL = `${window.location.origin}/images/bin.png`;

export const TOOLS_CATEGORY_ORDER = [
  ServiceSpecificationApiToolCategoryEnum.API_DOCUMENTATION,
  ServiceSpecificationApiToolCategoryEnum.API_SOURCE_CODE,
  ServiceSpecificationApiToolCategoryEnum.API_DEPLOYMENTS,
  ServiceSpecificationApiToolCategoryEnum.API_HEALTH,
  ServiceSpecificationApiToolCategoryEnum.API_SUPPORT,
];
export const TOOLS_CATEGORY_OPTIONS = [
  {
    value: ServiceSpecificationApiToolCategoryEnum.API_DOCUMENTATION,
    label: "API Documentation",
  },
  {
    value: ServiceSpecificationApiToolCategoryEnum.API_SOURCE_CODE,
    label: "API Source Code",
  },
  {
    value: ServiceSpecificationApiToolCategoryEnum.API_DEPLOYMENTS,
    label: "API Deployments",
  },
  {
    value: ServiceSpecificationApiToolCategoryEnum.API_HEALTH,
    label: "API Health",
  },
  {
    value: ServiceSpecificationApiToolCategoryEnum.API_SUPPORT,
    label: "API Support",
  },
];

export enum StatusEnum {
  SUCCESS = "success",
  SUCCESSFUL = "successful",
  FAILURE = "failed",
  WARNING = "warning",
}
