import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, ModalFuncProps } from "antd";
import { TFunction } from "i18next";

import styles from "./ModalConfirm.module.scss";

const { confirm } = Modal;

interface ModalConfirmProps extends ModalFuncProps {
  danger?: boolean;
}

export const modalConfirm = (
  t: TFunction<"translation">,
  {
    cancelText = t("default.cancelTitle"),
    okText = t("default.confirmTitle"),
    danger,
    ...rest
  }: ModalConfirmProps
) => {
  return confirm({
    cancelText,
    okText,
    width: 456,
    ...(danger && {
      okButtonProps: { danger: true },
      icon: <ExclamationCircleOutlined className={styles.danger} />,
    }),
    ...rest,
  });
};
