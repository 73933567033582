const InfoPreview = () => (
  <div>
    <p>Discover Apigale, your go-to API Business Platform! </p>
    <p>Unlock possibilities:</p>
    <ul>
        <li>
            API Providers to effortlessly manage and share API Services
        </li>
        <li>
            API Consumers to seamlessly explore, access, and try APIs
        </li>
    </ul>
    <p>Embark on the Beta Release journey with limited yet impactful features:</p>
    <ul>
        <li>Unleash the power of End-to-End API Lifecyсle Management</li>
        <li>Design and navigate API Journeys</li>
        <li>Elevate developer experience with the API drill-downs: from API Services through source code to
            deployment instances
        </li>
    </ul>
    <p>
        Your feedback is invaluable as we shape the future of APIs. More info at{" "}
        <a href="https://apigale.com">apigale.com</a>.
    </p>
  </div>
);

export default InfoPreview;
